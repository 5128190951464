/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { Catalog } from "../models/Catalog";
import type { CreateCatalogRequest } from "../models/CreateCatalogRequest";
import type { EventCatalogCatalogCreated } from "../models/EventCatalogCatalogCreated";
import type { EventCatalogCatalogDeleted } from "../models/EventCatalogCatalogDeleted";
import type { EventObjectObject } from "../models/EventObjectObject";

export class CatalogsService {
  /**
   * Get a list of catalogs in account
   * List Catalogs
   * @returns Catalog OK
   * @throws ApiError
   */
  public static list1(
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<Catalog>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/catalogs",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Create catalog
   * Create a new product catalog
   * @returns EventCatalogCatalogCreated Created
   * @throws ApiError
   */
  public static createCatalog(
    {
      requestBody,
    }: {
      requestBody: CreateCatalogRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<EventCatalogCatalogCreated> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/catalogs",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Get catalog history [EXPERIMENTAL]
   * @returns EventObjectObject OK
   * @throws ApiError
   */
  public static getHistory(
    {
      catalogId,
    }: {
      catalogId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<EventObjectObject>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/catalogs/{catalogId}/history",
      path: {
        catalogId: catalogId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Get a catalog
   * Get Catalog
   * @returns Catalog OK
   * @throws ApiError
   */
  public static get(
    {
      catalogId,
    }: {
      catalogId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Catalog> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/catalogs/{catalogId}",
      path: {
        catalogId: catalogId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Delete a catalog
   * Delete catalog
   * @returns EventCatalogCatalogDeleted OK
   * @throws ApiError
   */
  public static delete1(
    {
      catalogId,
    }: {
      catalogId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<EventCatalogCatalogDeleted> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/catalogs/{catalogId}",
      path: {
        catalogId: catalogId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
