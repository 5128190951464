import { v4 as uuidv4 } from "uuid";

import { ToastMessage } from "@web/ui";

import { useSystemMessagesContext } from "src/context/SystemMessages/SystemMessagesContext";

export const useSystemMessages = () => {
  const [, dispatch] = useSystemMessagesContext();

  const setSystemMessage = (systemMessage: Omit<ToastMessage, "id">) => {
    dispatch({
      type: "setSystemMessage",
      value: { ...systemMessage, id: uuidv4() },
    });
  };

  return { setSystemMessage };
};
