import { useMutation } from "@tanstack/react-query";

import { ProductSearchDownloadRequest, SearchService } from "@web/sherlock";

import { useSystemMessages } from "src/hooks/useSystemMessages";
import { getImageFilterQuery } from "src/utils";

import { ImageQualityFilter } from "../types";

export const PRODUCTS_DOWNLOAD_MUTATION_KEY = "productsDownload";

const buildSearchQuery = (
  search: string | undefined,
  imageQualityFilter: ImageQualityFilter = "ALL"
): string => {
  const imageFilterQuery = getImageFilterQuery(imageQualityFilter);
  const searchPart = search ? `${search}` : "";

  if (searchPart && imageFilterQuery) {
    return `${searchPart} AND ${imageFilterQuery}`;
  }

  return searchPart || imageFilterQuery;
};

export interface DownloadProductsParams {
  catalogId: string;
  imageQualityFilter: ImageQualityFilter;
  targetSrn?: string;
  locode?: string;
  search?: string;
}

export const useDownloadProducts = () => {
  const { setSystemMessage } = useSystemMessages();

  return useMutation({
    mutationKey: [PRODUCTS_DOWNLOAD_MUTATION_KEY],
    mutationFn: async ({
      catalogId,
      imageQualityFilter,
      targetSrn,
      locode,
      search,
    }: DownloadProductsParams) => {
      const headers = {
        Accept: "*/*",
      };

      const options = {
        headers,
      };

      const searchQuery = buildSearchQuery(search, imageQualityFilter);

      const requestBody: ProductSearchDownloadRequest = {
        query: searchQuery,
        sortField: "identifiers.sku",
        sortOrder: "asc",
        caller: "catalog-management",
        catalog: catalogId,
        downloadFormat: "XLSX",
      };

      if (locode) {
        requestBody.location = `srn:port::port/${locode}`;
      }

      if (targetSrn) {
        requestBody.target = targetSrn;
      }

      return SearchService.downloadProducts({ requestBody }, options);
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "Failed to download products. Please try again later.",
      });
    },
  });
};
