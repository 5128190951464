import { FC } from "react";

import { Heading, Paragraph } from "@web/ui";

type Props = {
  productsNumber: number;
};

const TotalProductsBox: FC<Props> = ({ productsNumber }) => {
  return (
    <div
      className="bg-neutral_100 border border-neutral-300 flex justify-center pl-5 flex-col w-[160px] h-[68px] mb-5"
      data-testid="totalProductBox"
    >
      <Paragraph size="200" weight="heavy">
        Total products
      </Paragraph>
      <Heading size="200" weight="heavy">
        {productsNumber}
      </Heading>
    </div>
  );
};

export default TotalProductsBox;
