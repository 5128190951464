import { useState } from "react";

import { Asset } from "@web/sherlock";
import { LinkButton, Paragraph } from "@web/ui";

type DigitalAssetsWrapperProps = {
  secondaryImages: Asset[];
  showLimit?: number;
  handleImageClick: (e: React.MouseEvent<Element>, imageUrl: string) => void;
};

export const SecondaryImagesWrapper = ({
  secondaryImages,
  showLimit = 12,
  handleImageClick,
}: DigitalAssetsWrapperProps) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <div className="mb-6">
      <Paragraph size="100" className="mb-4">
        <b>Secondary Images</b>
      </Paragraph>
      <div className="flex flex-wrap gap-6">
        {secondaryImages.map((image) => (
          <div key={image.url}>
            <img
              alt="product secondary image"
              src={image.url}
              width={160}
              height={160}
              className="border-1 rounded-2xl mb-4 object-contain w-[160px] h-[160px]"
              data-testid="productDetailsPage_secondaryImage"
              onClick={(e) => {
                image.url ? handleImageClick(e, image.url) : null;
              }}
            />
          </div>
        ))}
      </div>
      {secondaryImages.length > showLimit && (
        <LinkButton
          label={showAll ? "Show Less Images" : "Show All Images"}
          onClick={() => setShowAll(!showAll)}
          size="small"
          variant="primary"
        />
      )}
    </div>
  );
};
