import { DownloadIcon, XIcon } from "@heroicons/react/solid";
import { useAuth } from "oidc-react";
import { useCallback, useEffect, useState } from "react";

import { IntersectionMonitor } from "@web/common";
import { ProductResponse } from "@web/sherlock";
import { AgreementDetailsResponse, ContractListResponseItem } from "@web/sopimus";
import {
  Heading,
  Label,
  Loading,
  OptionType,
  Paragraph,
  RegularButton,
  SearchBar,
  Select,
} from "@web/ui";
import { triggerFileDownload } from "@web/utils";
import { Srn } from "@web/wallstreet";
import { Catalog } from "@web/warehouse";

import { Layout } from "src/components/Layout";
import { useCatalogContext } from "src/context/CatalogContext";
import { useDebouncedValue } from "src/utils";

import {
  useAgreementsQuery,
  useCatalogsQuery,
  useContractsQuery,
  useDownloadProducts,
  useImagesDataQuery,
} from "./api";
import { useAvailablePortsQuery } from "./api/useAvailablePortsQuery";
import { useInfiniteProductsQuery } from "./api/useProductsQuery";
import ImageOverviewBox from "./components/ImageOverviewBox";
import ProductsListTable from "./components/ProductsListTable";
import TotalProductsBox from "./components/TotalProductsBox";

export type ImageQualityFilterType = "EXISTING" | "MISSING" | "BROKEN" | "ALL";

export const CatalogsPage = () => {
  const auth = useAuth();
  const token = auth.userData?.access_token;

  const {
    selectedCatalog,
    setSelectedCatalog,
    selectedContract,
    setSelectedContract,
    selectedAgreement,
    setSelectedAgreement,
    selectedPort,
    setSelectedPort,
    locationCode,
    setLocationCode,
    productsList,
    setProductsList,
    showHint,
    setShowHint,
    searchInputValue,
    setSearchInputValue,
  } = useCatalogContext();
  const [shouldFetchProducts, setShouldFetchProducts] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [imageQualityFilter, setImageQualityFilter] = useState<ImageQualityFilterType>("ALL");
  const pageSize = 20;

  const [localSearchTerm, setLocalSearchTerm] = useState(searchInputValue);
  const debouncedSearchTerm = useDebouncedValue(localSearchTerm, 400);

  useEffect(() => {
    if (selectedPort?.value !== "select-port") {
      setIsAllSelected(true);
    }
  }, [selectedPort?.value]);

  useEffect(() => {
    setSearchInputValue(debouncedSearchTerm);
  }, [debouncedSearchTerm, setSearchInputValue]);

  useEffect(() => {
    if (selectedCatalog?.value !== "select-catalog" || searchInputValue !== "") {
      setShouldFetchProducts(true);
      setShowHint(false);
    } else {
      setShouldFetchProducts(false);
      setShowHint(true);
    }
  }, [selectedCatalog, searchInputValue, setShowHint, setShouldFetchProducts]);

  const {
    data: catalogsList,
    isPending: isCatalogsListPending,
    error: catalogsListError,
  } = useCatalogsQuery();

  const {
    data: contractsList,
    isPending: isContractsListPending,
    error: contractsListError,
  } = useContractsQuery(token || "");

  const {
    data: agreementsList,
    isPending: isAgreementsListPending,
    error: agreementsListError,
  } = useAgreementsQuery(token || "", selectedContract?.value || "", {
    enabled: selectedContract?.value !== "select-contract",
  });

  const {
    data: availablePortsList,
    isPending: isAvailablePortsListPending,
    error: availablePortsListError,
  } = useAvailablePortsQuery(
    token || "",
    selectedCatalog?.srn || "",
    selectedAgreement?.value || "",
    {
      enabled: selectedAgreement?.value !== "select-agreement",
    }
  );

  const {
    data: productsListData,
    isPending: isProductsListPending,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage: isFetchingMore,
  } = useInfiniteProductsQuery(
    selectedCatalog?.value === "select-catalog" && selectedCatalog?.srn !== undefined
      ? ""
      : selectedCatalog?.srn || "",
    pageSize,
    imageQualityFilter,
    selectedAgreement?.value === "select-agreement" ? "" : selectedAgreement?.value,
    locationCode === "select-port" ? undefined : locationCode,
    searchInputValue,
    {
      enabled: shouldFetchProducts,
    }
  );

  const {
    data: imagesData,
    isPending: isImagesDataPending,
    error: imagesDataError,
  } = useImagesDataQuery(
    selectedCatalog?.value || "",
    selectedAgreement?.value || "",
    locationCode === "select-port" ? undefined : locationCode,
    {
      enabled: shouldFetchProducts,
    }
  );

  const { mutate: downloadProducts, isPending: isDownloading } = useDownloadProducts();

  const filterValidCatalogs = (data: Catalog[]) => {
    return data.filter(
      (catalog): catalog is Catalog & { catalogId: string; name: string; srn: string } =>
        Boolean(catalog.catalogId) && Boolean(catalog.name) && Boolean(catalog.srn)
    );
  };

  const convertCatalogs = useCallback(
    (data: Catalog[]): (OptionType<string> & { srn?: string })[] => {
      if (data && data.length > 0 && catalogsListError === null) {
        return filterValidCatalogs(data).map((catalog) => ({
          value: catalog.catalogId,
          label: catalog.name,
          srn: catalog.srn,
        }));
      }
      return [{ value: "select-catalog", label: "Select catalog" }];
    },
    [catalogsListError]
  );

  const convertContracts = useCallback(
    (data: ContractListResponseItem[]) => {
      if (data && data.length > 0 && contractsListError === null) {
        return data.map((contract) => ({
          value: contract.id,
          label: contract.name,
        }));
      }
      return [{ value: "select-contract", label: "Select contract" }];
    },
    [contractsListError]
  );

  const convertAgreements = useCallback(
    (data: AgreementDetailsResponse[]) => {
      if (data && data.length > 0 && agreementsListError === null) {
        return data.map((agreement) => ({
          value: agreement.srn,
          label: agreement.name,
        }));
      }
      return [{ value: "select-agreement", label: "Select agreement" }];
    },
    [agreementsListError]
  );

  const convertPorts = useCallback(
    (data: Srn[]) => {
      if (data && data.length > 0 && availablePortsListError === null) {
        return data.map((port) => ({
          value: port.slice(-6),
          label: port.slice(-6),
        }));
      }
      return [{ value: "select-port", label: "Select port" }];
    },
    [availablePortsListError]
  );

  const updateIsAllSelected = useCallback(() => {
    const allSelected =
      selectedCatalog?.value !== "select-catalog" &&
      selectedContract?.value !== "select-contract" &&
      selectedAgreement?.value !== "select-agreement" &&
      selectedPort?.value !== "select-port";
    setIsAllSelected(allSelected);
  }, [selectedCatalog, selectedContract, selectedAgreement, selectedPort]);

  const handleFetchProducts = useCallback(() => {
    if (selectedCatalog?.value !== "select-catalog") {
      setShouldFetchProducts(true);
      setShowHint(false);
    }
  }, [selectedCatalog, setShowHint, setShouldFetchProducts]);

  const onSelectedCatalogChange = useCallback(
    (value: OptionType<string> & { srn?: string }) => {
      setSelectedCatalog(value);
      setSearchInputValue("");
      setLocalSearchTerm("");

      setSelectedContract({
        value: "select-contract",
        label: "Select contract",
      });
      setSelectedAgreement({
        value: "select-agreement",
        label: "Select agreement",
      });
      setSelectedPort({
        value: "select-port",
        label: "Select port",
      });
      setLocationCode("");

      handleFetchProducts();
      updateIsAllSelected();
      setIsAllSelected(false);
    },
    [
      setSelectedCatalog,
      setSearchInputValue,
      setLocalSearchTerm,
      setSelectedContract,
      setSelectedAgreement,
      setSelectedPort,
      setLocationCode,
      handleFetchProducts,
      updateIsAllSelected,
    ]
  );

  const onSelectedContractChange = useCallback(
    (value: OptionType<string>) => {
      setSelectedContract(value);
      setSearchInputValue("");
      setSelectedAgreement({
        value: "select-agreement",
        label: "Select agreement",
      });
      setSelectedPort({
        value: "select-port",
        label: "Select port",
      });
      setLocationCode("");
      setLocalSearchTerm("");
      handleFetchProducts();
      updateIsAllSelected();
      setIsAllSelected(false);
    },
    [
      setSelectedContract,
      setSearchInputValue,
      setSelectedAgreement,
      setSelectedPort,
      setLocationCode,
      setLocalSearchTerm,
      handleFetchProducts,
      updateIsAllSelected,
    ]
  );

  const onSelectedAgreementChange = useCallback(
    (value: OptionType<string>) => {
      setSelectedAgreement(value);
      setSearchInputValue("");
      setSelectedPort({
        value: "select-port",
        label: "Select port",
      });
      setLocationCode("");
      setLocalSearchTerm("");
      updateIsAllSelected();
      setIsAllSelected(false);
    },
    [
      setSelectedAgreement,
      setSearchInputValue,
      setSelectedPort,
      setLocationCode,
      setLocalSearchTerm,

      updateIsAllSelected,
    ]
  );

  const onSelectedPortChange = useCallback(
    (value: OptionType<string>) => {
      setSelectedPort(value);
      setLocationCode(value.value);
      handleFetchProducts();
      updateIsAllSelected();
      setIsAllSelected(true);
    },
    [setSelectedPort, setLocationCode, handleFetchProducts, updateIsAllSelected]
  );

  const onDownloadCatalog = useCallback(() => {
    if (!productsList?.length) {
      return;
    }

    const today = new Date();
    const dateString = today.toISOString().split("T")[0];

    const contractName = selectedContract?.label || "Default";
    const agreementName = selectedAgreement?.label || "Default";
    const portCode = selectedPort?.value || "Default";

    const fileName = `${contractName}_${agreementName}_${portCode}_${dateString}.csv`;
    const sanitizedFileName = fileName.replace(/[/\\:*?"<>|]/g, "_");

    downloadProducts(
      {
        catalogId:
          selectedCatalog?.value === "select-catalog" && selectedCatalog?.srn !== undefined
            ? ""
            : selectedCatalog?.srn || "",
        imageQualityFilter: imageQualityFilter,
        targetSrn: selectedAgreement?.value === "select-agreement" ? "" : selectedAgreement?.value,
        locode: locationCode === "select-port" ? undefined : locationCode,
        search: searchInputValue,
      },
      {
        onSuccess: (data) => {
          try {
            const blob = data as unknown as Blob;
            triggerFileDownload({
              file: blob,
              fileNameWithExtension: sanitizedFileName,
            });
          } catch (error) {
            console.error("Error processing download:", error);
          }
        },
      }
    );
  }, [
    productsList,
    selectedContract,
    selectedAgreement,
    selectedPort,
    selectedCatalog,
    imageQualityFilter,
    locationCode,
    searchInputValue,
    downloadProducts,
  ]);

  useEffect(() => {
    if (productsListData?.pages && !isProductsListPending) {
      const allProducts = productsListData.pages.flatMap((page) => page.products);
      setProductsList(allProducts as ProductResponse[]);
    }
  }, [isProductsListPending, productsListData?.pages, setProductsList, imageQualityFilter]);

  const existingImages = imagesData?.existing || 0;
  const brokenImages = imagesData?.broken || 0;
  const missingImages = imagesData?.missing || 0;
  const totalImages = existingImages + brokenImages + missingImages;
  const imageOverviewData = {
    labels: [
      `${existingImages} Showing image (${((existingImages / totalImages) * 100).toFixed(1)}%)`,
      `${missingImages} Image not provided (${((missingImages / totalImages) * 100).toFixed(1)}%)`,
    ],
    datasets: [
      {
        label: "",
        data: [existingImages, missingImages],
        backgroundColor: ["#3D7BF7", "#FBBB3C"],
        borderColor: ["#3D7BF7", "#FBBB3C"],
        borderWidth: 1,
      },
    ],
  };

  const isSearchEnabled = selectedCatalog?.value === "select-catalog";

  const shouldShowCatalogsLoader = isCatalogsListPending;
  const shouldShowContractsLoader =
    isContractsListPending && selectedCatalog?.value !== "select-catalog";
  const shouldShowAgreementsLoader =
    isAgreementsListPending &&
    selectedContract?.value !== "select-contract" &&
    selectedCatalog?.value !== "select-catalog";
  const shouldShowPortsLoader =
    isAvailablePortsListPending &&
    selectedContract?.value !== "select-contract" &&
    selectedCatalog?.value !== "select-catalog" &&
    selectedAgreement?.value !== "select-agreement";

  return (
    <Layout>
      <div className="flex flex-wrap space-x-0 sm:space-x-3 mb-5 w-full" data-testid="catalogsPage">
        <div className="w-full sm:w-auto sm:flex-1 min-w-0 mb-3 sm:mb-0 pr-3 sm:pr-0">
          <Label size="200" color="text-textIcon-blackPrimary">
            Supplier Catalog
          </Label>
          <div className="h-[40px] flex items-center relative">
            {shouldShowCatalogsLoader ? (
              <Loading />
            ) : (
              <Select
                data-testid="catalogsPage_supplierCatalogInput"
                placeholder="Select catalog"
                value={selectedCatalog}
                options={convertCatalogs(catalogsList as Catalog[]) as OptionType[]}
                onChange={onSelectedCatalogChange}
                className="w-full"
                dropdownHPosition="left-auto right-0"
              />
            )}
          </div>
        </div>

        <div className="w-full sm:w-auto sm:flex-1 min-w-0 mb-3 sm:mb-0 pr-3 sm:pr-0">
          <Label size="200" color="text-textIcon-blackPrimary">
            Contract
          </Label>
          <div className="h-[40px] flex items-center relative">
            {shouldShowContractsLoader ? (
              <Loading />
            ) : (
              <Select
                data-testid="catalogsPage_contractInput"
                placeholder="Select contract"
                value={selectedContract}
                options={
                  convertContracts(contractsList as ContractListResponseItem[]) as OptionType[]
                }
                onChange={onSelectedContractChange}
                disabled={selectedCatalog?.value === "select-catalog"}
                className="w-full"
                dropdownHPosition="left-auto right-0"
              />
            )}
          </div>
        </div>

        <div className="w-full sm:w-auto sm:flex-1 min-w-0 mb-3 sm:mb-0 pr-3 sm:pr-0">
          <Label size="200" color="text-textIcon-blackPrimary">
            Price Agreement
          </Label>
          <div className="h-[40px] flex items-center relative">
            {shouldShowAgreementsLoader ? (
              <Loading />
            ) : (
              <Select
                data-testid="catalogsPage_agreementInput"
                placeholder="Select agreement"
                disabled={
                  selectedContract?.value === "select-contract" ||
                  selectedCatalog?.value === "select-catalog"
                }
                value={selectedAgreement}
                options={
                  convertAgreements(agreementsList as AgreementDetailsResponse[]) as OptionType[]
                }
                onChange={onSelectedAgreementChange}
                className="w-full"
                dropdownHPosition="left-auto right-0"
              />
            )}
          </div>
        </div>

        <div className="w-full sm:w-auto sm:flex-1 min-w-0 mb-3 sm:mb-0">
          <Label size="200" color="text-textIcon-blackPrimary">
            Location
          </Label>
          <div className="h-[40px] flex items-center relative">
            {shouldShowPortsLoader ? (
              <Loading />
            ) : (
              <Select
                data-testid="catalogsPage_portInput"
                placeholder="Select port"
                disabled={
                  selectedContract?.value === "select-contract" ||
                  selectedCatalog?.value === "select-catalog" ||
                  selectedAgreement?.value === "select-agreement"
                }
                value={selectedPort}
                options={convertPorts(availablePortsList as Srn[]) as OptionType[]}
                onChange={onSelectedPortChange}
                className="w-full"
                dropdownHPosition="left-auto right-0"
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex items-start w-full">
        <div className="flex-grow">
          {!imagesDataError &&
            !isImagesDataPending &&
            searchInputValue === "" &&
            (selectedCatalog?.value !== "select-catalog" || shouldFetchProducts) &&
            totalImages > 0 && (
              <div className="flex space-x-4">
                <TotalProductsBox productsNumber={totalImages} />
                <ImageOverviewBox
                  imageOverviewData={imageOverviewData}
                  setImageQualityFilter={setImageQualityFilter}
                  imageQualityFilter={imageQualityFilter}
                  fetchProducts={() => handleFetchProducts()}
                  setShowHint={setShowHint}
                />
              </div>
            )}
        </div>

        <div className="flex ml-auto space-x-3">
          <div style={{ width: "250px" }}>
            <SearchBar
              data-testid="catalogsPage_searchInput"
              placeholder={isSearchEnabled ? "Select catalog to search" : "Search..."}
              onSubmit={(e) => {
                e.preventDefault();
              }}
              onQueryChange={(e) => setLocalSearchTerm(e.target.value)}
              query={localSearchTerm}
              removeOuterPaddings
              disabled={isSearchEnabled}
            />
          </div>
          <div>
            <RegularButton
              variant="primary"
              size="large"
              LeadingIcon={DownloadIcon}
              label="Download"
              onClick={onDownloadCatalog}
              loading={isDownloading}
              disabled={!productsList?.length || selectedPort?.value === "select-port"}
            />
          </div>
        </div>
      </div>

      {imageQualityFilter !== "ALL" && (
        <div
          className="flex mt-5 mb-5 bg-neutral_100 border border-neutral-300 pl-5 items-center py-1 w-[300px]"
          data-testid="catalogsPage_imageQualityFilter"
        >
          <Paragraph size="200">
            Active filter:{" "}
            <b>
              {imageQualityFilter === "EXISTING"
                ? "Showing image"
                : imageQualityFilter === "MISSING"
                ? "Image not provided"
                : "Broken image"}
            </b>
          </Paragraph>
          <div
            data-testid="catalogsPage_imageQualityFilterXIcon"
            className="ml-auto pr-2 cursor-pointer"
            onClick={() => {
              setImageQualityFilter("ALL");
              handleFetchProducts();
            }}
          >
            <XIcon className="h-5 w-5 text-textIcon-whiteDisabled" aria-hidden="true" />
          </div>
        </div>
      )}

      <div className="mt-5 relative z-0">
        {!isProductsListPending && shouldFetchProducts && (
          <ProductsListTable
            catalogId={selectedCatalog?.value || ""}
            productsList={productsList}
            isAllSelected={isAllSelected}
            isLoading={isProductsListPending}
            isSearchEmpty={searchInputValue === ""}
          />
        )}

        {isProductsListPending && shouldFetchProducts && <Loading />}

        {showHint && (
          <div
            className="flex center items-center w-full justify-center mt-20"
            data-testid="catalogsPage_hint"
          >
            <div>
              <Heading size="300" color="text-textIcon-blackSecondary" className="mb-4">
                1. Select a Supplier Catalog to view your product list.
              </Heading>
              <Heading size="300" color="text-textIcon-blackSecondary">
                2. Select a Supplier Catalog, Contract, Price Agreement and Location to view your
                prices.
              </Heading>
            </div>
          </div>
        )}

        {hasNextPage && shouldFetchProducts && (
          <IntersectionMonitor
            onEnter={() => {
              fetchNextPage();
            }}
          >
            <div className="h-10">{isFetchingMore && <Loading />}</div>
          </IntersectionMonitor>
        )}
      </div>
    </Layout>
  );
};
