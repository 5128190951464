import {
  Navigate,
  Route,
  Routes as RoutesSwitch,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { NotFoundErrorPage } from "@web/ui";

import { ErrorBoundary } from "./components/ErrorBoundary";
import { RoutesConfig } from "./config/routes";
import { CatalogsPage } from "./pages/CatalogsPage";
import { DashboardPage } from "./pages/DashboardPage";
import { ProductDetailsPage } from "./pages/ProductDetailsPage";

export const Routes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ErrorBoundary navigate={navigate} pathname={location.pathname}>
      <RoutesSwitch>
        <Route index element={<Navigate to={`${RoutesConfig.catalogs}?catalogs`} />} />
        <Route path={RoutesConfig.catalogs} element={<CatalogsPage />} />
        <Route path={RoutesConfig.dashboard} element={<DashboardPage />} />
        <Route path={RoutesConfig.productDetails} element={<ProductDetailsPage />} />
        <Route
          path="*"
          element={<NotFoundErrorPage onButtonClick={() => navigate(RoutesConfig.home)} />}
        />
      </RoutesSwitch>
    </ErrorBoundary>
  );
};
