import { XIcon } from "@heroicons/react/solid";

import { IconButton } from "@web/ui";

interface Props {
  imageUrl: string;
  onClose: () => void;
}

export const ImagePreview: React.FC<Props> = ({ imageUrl, onClose }) => {
  return (
    <>
      <div
        className="fixed inset-0 z-40 bg-black bg-opacity-60"
        aria-hidden="true"
        onClick={onClose}
      />

      <div
        className="fixed inset-0 z-50 flex items-center justify-center pl-[150px] ml-[150px]"
        role="dialog"
        aria-modal="true"
        onClick={onClose}
      >
        <div
          className="relative bg-white p-6 rounded-md max-h-screen max-w-screen-lg overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="absolute top-2 right-2">
            <IconButton
              variant="secondary"
              shape="circle"
              size="small"
              label="Close"
              Icon={XIcon}
              onClick={onClose}
              className="bg-white shadow-md z-10"
            />
          </div>
          <img
            src={imageUrl}
            alt="Product preview"
            className="max-w-full max-h-full object-contain"
          />
        </div>
      </div>
    </>
  );
};
