import {
  DefaultError,
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";

import { ProductResultResponse, ProductSearchRequest, SearchService } from "@web/sherlock";

import { getImageFilterQuery } from "src/utils";

import { ImageQualityFilter } from "../types";

export const PRODUCTS_QUERY_KEY_BASE = "products";

const buildSearchQuery = (
  search: string | undefined,
  imageQualityFilter: ImageQualityFilter = "ALL"
): string => {
  const imageFilterQuery = getImageFilterQuery(imageQualityFilter);
  const searchPart = search ? `${search}` : "";

  if (searchPart && imageFilterQuery) {
    return `${searchPart} AND ${imageFilterQuery}`;
  }

  return searchPart || imageFilterQuery;
};

const fetchProducts = async ({
  location,
  target,
  imageQualityFilter,
  query,
  paging,
  sortField,
  caller,
  catalog,
}: {
  location?: string;
  target: string;
  imageQualityFilter: ImageQualityFilter;
  query: string | undefined;
  paging: {
    offset: number;
    limit: number;
  };
  sortField: string;
  caller: string;
  catalog: string;
}): Promise<ProductResultResponse> => {
  const headers = {
    Accept: "*/*",
  };

  const options = {
    headers,
  };

  const searchQuery = buildSearchQuery(query, imageQualityFilter);

  const requestBody: ProductSearchRequest = {
    query: searchQuery,
    paging: {
      offset: paging.offset,
      limit: paging.limit,
    },
    sortField,
    sortOrder: "asc",
    caller,
    catalog,
  };

  if (location) {
    requestBody.location = location;
  }

  if (target) {
    requestBody.target = target;
  }

  const response = await SearchService.products({ requestBody }, options);

  return {
    ...response,
    products: response.products?.map((product) => ({
      ...product,
      id: product.productId,
      name: product.name,
    })),
  };
};

const getNextPageParam = (lastPage: ProductResultResponse, allPages: ProductResultResponse[]) => {
  const totalFetched = allPages.reduce((acc, page) => acc + (page.products?.length || 0), 0);
  const totalHits = lastPage.hits || 0;

  if (totalFetched < totalHits) {
    return totalFetched;
  }

  return undefined;
};

export const useInfiniteProductsQuery = (
  catalogId: string,
  size: number,
  imageQualityFilter: ImageQualityFilter,
  targetSrn?: string,
  locode?: string,
  search?: string,
  queryOptions: Partial<
    UseInfiniteQueryOptions<
      ProductResultResponse,
      DefaultError,
      InfiniteData<ProductResultResponse>,
      ProductResultResponse,
      QueryKey,
      number
    >
  > = {}
) =>
  useInfiniteQuery<
    ProductResultResponse,
    DefaultError,
    InfiniteData<ProductResultResponse>,
    QueryKey,
    number
  >({
    queryKey: [
      PRODUCTS_QUERY_KEY_BASE,
      catalogId,
      search,
      size,
      imageQualityFilter,
      locode,
    ],
    queryFn: ({ pageParam = 0 }) =>
      fetchProducts({
        location: locode ? `srn:port::port/${locode}` : undefined,
        target: targetSrn || "",
        imageQualityFilter,
        query: search,
        paging: {
          offset: pageParam,
          limit: size,
        },
        sortField: "identifiers.sku",
        caller: "catalog-management",
        catalog: catalogId,
      }),
    initialPageParam: 0,
    getNextPageParam,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    ...queryOptions,
  });
