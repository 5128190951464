import { InformationCircleIcon } from "@heroicons/react/outline";
import { ActiveElement, ArcElement, ChartEvent, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Dispatch, FC, SetStateAction } from "react";
import { Pie } from "react-chartjs-2";

import { Paragraph, Tooltip as TooltipIcon } from "@web/ui";

import { ImageQualityFilterType } from "../CatalogsPage";

ChartJS.register(ArcElement, Tooltip, Legend);

interface ImageOverviewBoxProps {
  setImageQualityFilter: Dispatch<SetStateAction<ImageQualityFilterType>>;
  fetchProducts: () => void;
  imageQualityFilter: ImageQualityFilterType;
  setShowHint: (value: boolean) => void;
  imageOverviewData: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth: number;
    }[];
  };
}

const ImageOverviewBox: FC<ImageOverviewBoxProps> = ({
  imageOverviewData,
  setImageQualityFilter,
  imageQualityFilter,
  fetchProducts,
  setShowHint,
}) => {
  const totalImages = imageOverviewData.datasets[0].data.reduce((sum, count) => sum + count, 0);

  const existingPercentage = (imageOverviewData.datasets[0].data[0] / totalImages) * 100;
  const brokenPercentage = (imageOverviewData.datasets[0].data[1] / totalImages) * 100;
  const missingPercentage = (imageOverviewData.datasets[0].data[2] / totalImages) * 100;

  const hasFullPercentage = [existingPercentage, brokenPercentage, missingPercentage].some(
    (percentage) => percentage === 100
  );

  const labelToFilter = (label: string): ImageQualityFilterType => {
    if (label.includes("Showing")) {
      return "EXISTING";
    } else if (label.includes("Broken")) {
      return "BROKEN";
    } else {
      return "MISSING";
    }
  };

  const handleChartClick = (label: string) => {
    const filter = labelToFilter(label);
    setImageQualityFilter(filter);
    fetchProducts();
    setShowHint(false);
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        boxWidth: 10,
        boxHeight: 10,
        padding: 10,
      },
      hover: {
        mode: null,
      },
    },
    events: ["click"] as Array<keyof HTMLElementEventMap>,
    onClick: (event: ChartEvent, elements: ActiveElement[]) => {
      if (elements.length > 0) {
        const chartElement = elements[0];
        const index = chartElement.index;
        const label = imageOverviewData.labels[index];
        handleChartClick(label);
      }
    },
  };

  const updatedImageOverviewData = {
    ...imageOverviewData,
    datasets: imageOverviewData.datasets.map((dataset) => ({
      ...dataset,
      borderColor: "white",
      borderWidth: hasFullPercentage ? 0 : 2,
    })),
  };

  return (
    <div
      className="bg-neutral_100 border border-neutral-300 pl-5 py-2 mb-5 w-[380px]"
      data-testid="imageOverviewBox"
    >
      <div className="flex">
        <Paragraph size="200" weight="heavy" className="mb-1">
          Image Overview
        </Paragraph>
      </div>
      <div className="flex items-center">
        <div className="w-1/3 overflow-visible cursor-pointer">
          <Pie data={updatedImageOverviewData} options={options} />
        </div>
        <div className="pl-3">
          {imageOverviewData.labels.map((label, index) => (
            <div
              key={index}
              className="flex items-center mb-2 cursor-pointer"
              onClick={() => handleChartClick(label)}
            >
              <div
                className="w-4 h-4 mr-1"
                style={{ backgroundColor: imageOverviewData.datasets[0].backgroundColor[index] }}
              ></div>
              <Paragraph
                size="300"
                className={`flex justify-around hover:font-bold ${
                  imageQualityFilter === labelToFilter(label) ? "font-bold" : ""
                }`}
              >
                {label}{" "}
                <TooltipIcon
                  hAlignTo="start"
                  vAlignTo="bottom"
                  tooltipText={
                    label.includes("Showing")
                      ? `Showing image = an image was provided and is showing correctly on S2S.`
                      : label.includes("Broken")
                      ? "Image broken = an image or url was provided but fails to display correctly."
                      : "Image not provided = no image was provided for this item."
                  }
                  className="ml-2 "
                >
                  <InformationCircleIcon className="w-4 h-4 text-textIcon-blackSecondary" />
                </TooltipIcon>
              </Paragraph>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageOverviewBox;
