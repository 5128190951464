import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ProductResponse } from "@web/sherlock";
import { IRow, Table } from "@web/ui";
import { formatISODateDurationAsNumberOfDays, imagor } from "@web/utils";

import { ImagePreview } from "src/components/ImagePreview";
import errorIcon from "src/components/icons/ErrorIcon.svg";
import imagePlaceholder from "src/components/icons/image_placeholder.png";
import { filterOutImagesAssets, sortImagesByPriority } from "src/utils";

type Props = {
  catalogId: string;
  isAllSelected: boolean;
  isSearchEmpty: boolean;
  productsList?: ProductResponse[];
  isLoading?: boolean;
};

const ProductsListTable = ({
  catalogId,
  isLoading,
  productsList,
  isAllSelected,
  isSearchEmpty,
}: Props) => {
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const handleRowClick = useCallback(
    (id?: string) => {
      navigate(`/catalogs/${catalogId}/products/${id}`);
    },
    [catalogId, navigate]
  );

  const handleImageClick = useCallback((e: React.MouseEvent, imageUrl: string) => {
    e.stopPropagation();
    setPreviewImage(imageUrl);
  }, []);

  const closePreview = useCallback(() => {
    setPreviewImage(null);
  }, []);

  const renderCellData = (data: string | number | null | undefined) =>
    data !== undefined && data !== null ? data : <img alt="missing data" src={errorIcon} />;

  const rows = useMemo(() => {
    return productsList?.map((item: ProductResponse): IRow => {
      const sortedImages = sortImagesByPriority(item?.assets || []);
      const imageUrl = sortedImages[0]?.url;
      const processedImageUrl = imageUrl ? imagor(imageUrl) : imagePlaceholder;

      const price = item?.prices?.[0];

      return {
        onClick: () => handleRowClick(item?.productId),
        data: [
          {
            type: "icon",
            data: (
              <img
                alt="product image"
                src={processedImageUrl}
                width="5rem"
                height="5rem"
                className="border-1 w-7 h-7 rounded-full my-1 object-cover cursor-pointer"
                onClick={(e) => {
                  imageUrl ? handleImageClick(e, imageUrl) : null;
                }}
              />
            ),
          },
          {
            type: "title",
            data: renderCellData(item?.identifiers?.sku || item?.productId),
          },
          {
            type: "title",
            data: renderCellData(item?.name),
          },
          {
            type: "title",
            data: isAllSelected && price ? renderCellData(`${price.price} ${price.currency}`) : "",
          },
          {
            type: "title",
            data: isAllSelected
              ? renderCellData(formatISODateDurationAsNumberOfDays(item?.leadTime as string))
              : "",
          },
          {
            type: "title",
            data: isAllSelected
              ? renderCellData(item?.defaultOrderQuantity?.minimumOrderQuantity)
              : "",
          },
          {
            type: "title",
            data: isAllSelected
              ? renderCellData(item?.defaultOrderQuantity?.orderQuantityIncrement)
              : "",
          },
          {
            type: "title",
            data: isAllSelected ? renderCellData(item?.displayUnit) : "",
          },
          {
            type: "title",
            data: renderCellData(filterOutImagesAssets(item?.assets || []).length),
          },
        ],
      };
    });
  }, [productsList, handleRowClick, handleImageClick, isAllSelected]);

  return (
    <>
      <Table
        data-testid="productListTable"
        headings={[
          { text: "Image" },
          { text: "Supplier Art. Code" },
          { text: "Short description" },
          { text: "Price" },
          { text: "Lead time" },
          { text: "Min. Order Qty." },
          { text: "Quantity Steps" },
          { text: "Unit of measure" },
          { text: "Attachments" },
        ]}
        isLoading={isLoading}
        rows={rows}
        emptyState={
          isAllSelected || !isSearchEmpty
            ? {
                title: "No results",
                subtitle: "We couldn’t find any products matching that criteria",
              }
            : {
                title: "No products",
                subtitle: "This Supplier Catalog doesn’t have any products yet",
              }
        }
      />
      {previewImage && <ImagePreview imageUrl={previewImage} onClose={closePreview} />}
    </>
  );
};

export default ProductsListTable;
